body {
  background-repeat: no-repeat;
  background-image: url("./img/back.png");
}

th {
  background-color: #7fff00 !important;
}

.exit-icon {
  filter: brightness(0);
  height: 28px;
}

.border-right-zoom {
  border-right: 1px solid black;
}

.main {
  min-height: 75vh;
}

.footer {

  width: 100%;
  text-align: center;
  padding: 10px;
}

.osi-logo {
  filter: brightness(0);
}

.text-bold {
  font-weight: 500;
}

.zoom-img {
  width: 20%;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  min-height: 9em;
}

nav a {
  text-decoration: none;
  color: black;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center-div {
  height: 100vh;
  align-items: center;
}

a {
  color: black !important;
  text-decoration: none !important;

}

.page-item button {
  color: #212529 !important;
}

.login {
  margin-top: 30vh;
}

.bg-light {
  --bs-bg-opacity: none !important;
}

.page-item.active .page-link {
  color: white !important;
  border-color: black !important;
  background-color: black !important;
}

.icon:not(.icon-c-s):not(.icon-custom-size) {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.table .table-bordered .table-striped .table-hover {
  text-align: center;

}

.table {
  border-color: transparent !important;
}

.container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}

.container-2 .card-2 {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

.container-2 .card-2:nth-child(1) .box-2 .content-2 a {
  background: #7fff00;
}

.container-2 .card-2:nth-child(2) .box-2 .content-2 a {
  background: #7fff00;
}


.container-2 .card-2 .box-2 {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.container-2 .card-2 .box-2:hover {
  transform: translateY(-50px);
}

.container-2 .card-2 .box-2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.container-2 .card-2 .box-2 .content-2 {
  padding: 20px;
  text-align: center;
}


.container-2 .card-2 .box-2 .content-2 h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 4rem;
  color: rgb(255 255 255 / 21%);
}

.container-2 .card-2 .box-2 .content-2 h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.container-2 .card-2 .box-2 .content-2 p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.container-2 .card-2 .box-2 .content-2 a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

@media only screen and (max-width: 768px) {
  .table-scroll {
    width: 100%;
    overflow-x: scroll;

  }
}

.exit-icon svg {
  font-size: 2rem;
  color: black;
  cursor: pointer;
}

.exit-icon svg:hover {
  color: #6ebc20;
}

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container-2 .card-2 .box-2 .content-2 a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.option svg:nth-child(1) {
  font-size: 1.5rem;
  color: #6ebc20;
  cursor: pointer;
}

.val-message {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.option svg:nth-child(2) {
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}